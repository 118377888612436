import React from 'react';
import ClientLogos from './ClientListLogos';

export default () => (
  <section id="client-list" className="center">
    <div className="container">
      <h4>
        Some <strong>great folks</strong> we’ve worked with
      </h4>
      <ClientLogos />
    </div>
  </section>
);
