import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import DefaultLayout from '../layouts/DefaultLayout';
import ContactForm from '../components/ContactForm';
import ClientList from '../components/ClientList';

import projectWegotchu from '../images/landing/project-wegotchu.png';
import projectFacecamp from '../images/landing/project-facecamp.gif';
import projectWildling from '../images/landing/project-wildling.png';
import projectFYW from '../images/landing/project-find-your-weirdos.png';
import projectMOW from '../images/landing/project-meet-our-weirdos.png';
import projectCoolkit from '../images/landing/project-wicked-coolkit.png';


import headerText from '../images/header-text.svg';
import hero from '../images/hero-desktop.svg';
import letteringSoftware from '../images/landing/lettering-software-is-about-people.svg';
import illoYeti from '../images/yeti.svg';
import illoServices from '../images/services.svg';

import iconNametag from '../images/icons/colorful_nametag.svg';
import iconInterface from '../images/icons/colorful_interface.svg';
import iconLightbulb from '../images/icons/pink_lightbulb.svg';
import iconPencils from '../images/icons/colorful_pencils.svg';
import iconComputer from '../images/icons/colorful_computer.svg';
import iconTshirt from '../images/icons/colorful_tshirt.svg';
import iconBook from '../images/icons/colorful_book.svg';
import iconMobile from '../images/icons/colorful_mobile.svg';
import iconShip from '../images/icons/colorful_ship.svg';
import iconMap from '../images/icons/colorful_map.svg';
import iconOss from '../images/icons/colorful_oss.svg';

export default () => (
  <DefaultLayout
    pageTitle="Home"
    pageDescription="People-first designers and developers to complete your team"
    bodyClass="landing"
  >
    <div className="container">
      <section>

        <h1 className="main">
          <span><strong>Howdy!</strong></span>
          We’re &yet, a full service <strong>design</strong> and <strong>development</strong> agency.
        </h1>

        <img className="hero" src={hero} alt=""/>

        <div className="title-section">
          <div className="title-wrapper">
            <div className="title-large sandwich">
              <h2><em>We're sandwich enthusiasts.</em></h2>
            </div>
          </div>
        </div>

        <div className="vertical-line height-98"></div>

        <h3 className="center">Lettuce elaborate.</h3>

        <div className="content-narrower center">
          <p>We’re also <strong>design and code veterans</strong> who excel at collaborating. Above and beyond our technical expertise, we bring a <strong>people-first philosophy</strong> to every project.</p>
        </div>
      </section>
      <section>
        <div className="content-narrower center mb2">
          <h2><strong>Our services</strong></h2>
          <p>
            We believe in the immense value created when design and development
            are aligned with your strategy and goals.
          </p>
        </div>
        <div class="services-list">
          <div class="services-item">
            <img className="icon" src={iconInterface} alt="website interface" />
            <div>
              <h3>Websites</h3>
              <p>
                Responsive and accessible UI design with resilient HTML, CSS,
                and JavaScript development.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img className="icon" src={iconMobile} alt="mobile devices" />
            <div>
              <h3>Apps</h3>
              <p>
                Web and mobile apps, SPAs, and SAAS products with thoughtful UX
                and robust implementation.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img className="icon" src={iconShip} alt="rocket ship" />
            <div>
              <h3>Prototypes</h3>
              <p>
                Quick and iterative development that gets you to a proof of
                concept.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img
              className="icon"
              src={iconMap}
              alt="unfolded map with route and destination marked"
            />
            <div>
              <h3>Architecture</h3>
              <p>
                Software plans that scale, designed to be dependable and
                low-maintenance.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img className="icon" src={iconComputer} alt="laptop" />
            <div>
              <h3>Creative demos</h3>
              <p>
                Showcase your product in fun (and weird) ways that effectively
                get folks on board.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img
              className="icon"
              src={iconOss}
              alt="a terminal window with a heart"
            />
            <div>
              <h3>React & Node.js</h3>
              <p>
                We can hop into any stack, but we are especially great in
                JavaScript world.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img
              className="icon"
              src={iconNametag}
              alt="name tag that says hello"
            />
            <div>
              <h3>Branding</h3>
              <p>
                Identity, logos, and style guides that people will love and
                remember.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img className="icon" src={iconTshirt} alt="tshirt" />
            <div>
              <h3>Swag</h3>
              <p>
                Physical objects designed with care that people <em>want</em> to
                keep.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img className="icon" src={iconBook} alt="open book" />
            <div>
              <h3>Graphic design</h3>
              <p>
                We do print too! Books, posters, flyers, event materials, you
                name it.
              </p>
            </div>
          </div>
          <div class="services-item">
            <img className="icon" src={iconPencils} alt="crossed pencils" />
            <div>
              <h3>Illustration</h3>
              <p>
                Drawings that bring life to your products. (We can animate them
                too.)
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="how-we-work">
        <div>
          <img
            className="illo"
            src={illoServices}
            alt="an app window with code, a chart, a bulleted text, and a node tree"
          />
        </div>
        <div>
          <h2><strong>How we work</strong></h2>
          <h5 className="mb0">High trust and collaboration</h5>
          <p>
            We appreciate flexiblity over process, and value kind, honest
            communication.
          </p>
          <h5 className="mb0">Share our work early and often</h5>
          <p>
            We want to get feedback quickly and early. This helps us to
            prioritize the right improvements and reduces the cost of
            speculative development.
          </p>
          <h5 className="mb0">Favor simplicity and sustainability</h5>
          <p>
            We believe it’s clever not to be clever. Software should be
            dependable and low-maintenance.
          </p>
          <h5 className="mb0">Use the right tool for the job</h5>
          <p>
            While we have favorite languages and libraries, we reach for the
            tools that make the most sense for your project.
          </p>
        </div>
      </section>
    </div>

    <div className="blue-section">
      <ClientList />
    </div>

    <div className="container">
      <section className="weirdos-projects">
        <div className="content-narrower mb2 center">
          <h2 className="center"><strong>Recent projects</strong></h2>
          <p>
            We can’t show lot of our work (keep it secret, keep it safe), but
            here are a few recent projects we made just for us.
          </p>
        </div>
        <ul className="project-card-list">
          <li className="project-card">
            <a href="https://find.yourweirdos.com">
              <img src={projectFYW} alt="Find Your Weirdos" />
            </a>
            <a href="https://find.yourweirdos.com">Find Your Weirdos</a>
            <br />
            <span>
              A guide to connecting with customers who share your niche
              fascinations—and earning their wholehearted patronage
            </span>
          </li>
          <li className="project-card">
            <a href="https://ourweirdosmightbe.yourweirdos.com">
              <img src={projectMOW} alt="Meet Our Weirdos" />
            </a>
            <a href="https://ourweirdosmightbe.yourweirdos.com">
              Meet Our Weirdos
            </a>
            <br />
            <span>
              We want to introduce you to some amazing weirdos we love (and
              suspect you will too)
            </span>
          </li>
          <li className="project-card">
            <a href="https://wickedcoolkit.com/">
              <img src={projectCoolkit} alt="Wicked Coolkit" />
            </a>
            <a href="https://wickedcoolkit.com/">Wicked Coolkit</a>
            <br />
            <span>
              A fun, nostalgic web toolkit to celebrate your unique place in
              this weird web world
            </span>
          </li>
          <li className="project-card">
            <a href="https://wegotchu.cards">
              <img src={projectWegotchu} alt="fanned out greeting cards" />
            </a>
            <a href="https://wegotchu.cards">Wegotchu</a>
            <br />
            <span>
              Digital cards to pass around and sign, even when you’re not
              together
            </span>
          </li>
          <li className="project-card">
            <a href="https://face.camp">
              <img
                src={projectFacecamp}
                alt="Slack chat with an animated gif of a person waving"
              />
            </a>
            <a href="https://face.camp">Facecamp</a>
            <br />
            <span>
              A simple way to post animated gifs of your face in Slack
            </span>
          </li>
          <li className="project-card">
            <a href="https://wildling.co">
              <img src={projectWildling} alt="Call of the Wildling logo" />
            </a>
            <a href="https://wildling.co">Call of the Wildling</a>
            <br />
            <span>
              A story about pursuing your gifts without being drowned by the
              needs of the world
            </span>
          </li>
        </ul>

      <div className="sandwich-answer center">
        <h4>What does any of this have to do with sandwiches?!</h4>
        <p>Honestly, nothing — but we appreciate that you like to get to the bottom of things. So do we! Asking the right questions such an important part of doing great work. <strong>We're going to get along great!</strong></p>
      </div>

      </section>

      <section id="contact-form" className="show-contact">
        <div className="content-narrower mb2 center">
          <h2>
            What can we do for you?
            <br />
            <strong>Get in touch, and let’s talk.</strong>
          </h2>
          <p>
            You can email{' '}
            <a href="mailto:contact@andyet.com">contact@andyet.com</a> or fill
            out the form below.
          </p>
          <ContactForm />
        </div>
      </section>
    </div>

    <section className="featured-quote">
      <div className="container">
        <blockquote className="blockquote-medium">
          <p>
          “&yet delivers carefully hand crafted products, made by humans who love their work.”
          </p>
          <footer>
            Paolo Fragomeni
          </footer>
        </blockquote>
      </div>
    </section>
  </DefaultLayout>
);
