import React from 'react';

import logoCaa from "../images/logos/logo-caa.svg";
import logoAtt from "../images/logos/logo-att.svg";
import logoHeroku from "../images/logos/logo-heroku.svg";
import logoSalesforce from "../images/logos/logo-salesforce.svg";
import logoProtocolLabs from "../images/logos/logo-protocol-labs.svg";
import logoQuip from "../images/logos/logo-quip.svg";
import logoPnnl from "../images/logos/logo-pnnl.svg";
import logoBox from "../images/logos/logo-box.svg";
import logoMls from "../images/logos/logo-mls.svg";
import logoWalmart from "../images/logos/logo-walmart.svg";
import logoMercatalyst from "../images/logos/logo-mercatalyst.png";
import logoArmory from "../images/logos/logo-armory.svg";

export default () => (
  <ul className="client-list list-unstyled">
    <li className="logo-client">
      <a href="//salesforce.com">
        <img
          className="logo"
          src={logoSalesforce}
          alt="Salesforce"
        />
      </a>
    </li>
    <li className="logo-client">
      <a href="//caa.com">
        <img
          className="logo"
          src={logoCaa}
          alt="CAA" />
      </a>
    </li>
    <li className="logo-client">
      <a href="//att.com">
        <img className="logo" src={logoAtt} alt="AT&amp;T" />
      </a>
    </li>
    <li className="logo-client">
      <a href="//heroku.com">
        <img
          className="logo"
          src={logoHeroku}
          alt="Heroku"
        />
      </a>
    </li>
    <li className="logo-client">
      <a href="//walmart.com">
        <img
          className="logo"
          src={logoWalmart}
          alt="Walmart" />
      </a>
    </li>
    <li className="logo-client">
      <a href="//protocol.ai">
        <img
          className="logo"
          src={logoProtocolLabs}
          alt="Protocol Labs"
        />
      </a>
    </li>
    <li className="logo-client">
      <a href="//mercatalyst.com/">
        <img
          className="logo"
          src={logoMercatalyst}
          alt="Mercatalyst" />
      </a>
    </li>
    <li className="logo-client">
      <a href="//quip.com">
        <img
          className="logo"
          src={logoQuip}
          alt="Quip" />
      </a>
    </li>
    <li className="logo-client">
      <a href="//pnnl.gov">
        <img
          className="logo"
          src={logoPnnl}
          alt="PNNL" />
      </a>
    </li>
    <li className="logo-client">
      <a href="//box.com">
        <img
          className="logo"
          src={logoBox}
          alt="box" />
      </a>
    </li>
    <li className="logo-client">
      <a href="//www.mlssoccer.com/">
        <img
          className="logo"
          src={logoMls}
          alt="Major League Soccer" />
      </a>
    </li>
    <li className="logo-client">
      <a href="//armory.io/">
        <img
          className="logo"
          src={logoArmory}
          alt="Armory" />
      </a>
    </li>
  </ul>
);
